import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const [isContact, setIsContact] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Check if the current URL matches a specific path
    setIsContact(location.pathname === "/contact");
    setIsHome(location.pathname === "/");
  }, [location.pathname]);
  return (
    <div>
      <Helmet>
        <title>Cianata</title>
        <meta
          name="description"
          content="Helping companies with AI implementation. From stunning designs to powerful apps, we redefine digital success. Explore with us now!"
        />
        <meta property="og:title" content="Blog2 - Cianata" />
        <meta
          property="og:description"
          content="Helping companies with AI implementation. From stunning designs to powerful apps, we redefine digital success. Explore with us now!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ed6350b0-e4c0-40f3-8286-ad2cb4623861/501229ca-afa4-48e1-9e6f-10837214ccce?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div
        className="blog2-navbar"
        style={
          isContact
            ? { backgroundColor: "#e5fe58" }
            : isHome
            ? { backgroundColor: "#dfe7dd" }
            : {}
        }
      >
        <header data-role="Header" className="blog2-header max-width-container">
          <div className="blog2-navbar1">
            <div className="blog2-middle">
              <div className="blog2-left">
                <a
                  href={isHome ? "#call" : "/contact"}
                  className="blog2-text navbar-link"
                >
                  BOOK A CALL
                </a>
                <a
                  href="https://wa.me/+916238176858?text=Hello%20there,%20I%20wanted%20to%20know%20more."
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog2-text01 navbar-link"
                >
                  CHAT
                </a>
                <a href="/#Services" className="blog2-text02 navbar-link">
                  <span>SERVICES</span>
                  <br />
                </a>
              </div>
              <div className="blog2-container1">
                <img
                  alt="image"
                  src="/external/cianatanew-removebg-preview-200h.png"
                  className="blog2-image"
                />
                <Link to="/" className="blog2-logo-center navbar-logo-title">
                  CIANATA
                </Link>
              </div>
              <div className="blog2-right">
                <a href="/#pricing" className="blog2-text05 navbar-link">
                  PRICING
                </a>
                <Link to="/blog" className="blog2-text06 navbar-link">
                  BLOG
                </Link>
                <Link to="/contact" className="blog2-text07 navbar-link">
                  CONTACT
                </Link>
              </div>
            </div>
          </div>
          <div
            className="blog2-burger-menu"
            onClick={toggleMobileMenu}
            style={{ cursor: "pointer" }}
          >
            <svg
              viewBox="0 0 1024 1024"
              style={{ fill: "black" }}
              className="blog2-icon"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            className={`blog2-mobile-menu ${isMobileMenuOpen ? "open" : ""}`}
            onClick={closeMobileMenu}
          >
            <div className="blog2-nav">
              <div className="blog2-container2">
                <span className="blog2-logo-center1">CIANATA</span>
                <div
                  data-role="CloseMobileMenu"
                  className="blog2-close-mobile-menu"
                  onClick={toggleMobileMenu}
                  style={{ cursor: "pointer" }}
                >
                  <svg viewBox="0 0 1024 1024" className="blog2-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="blog2-middle1">
                <Link to="/contact" className="blog2-text08">
                  BOOK A CALL
                </Link>
                <a
                  href="https://wa.me/+916238176858?text=Hello%20there,%20I%20wanted%20to%20know%20more."
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog2-text09"
                >
                  CHAT
                </a>
                <Link to="/" className="blog2-text10">
                  PRICING
                </Link>
                <Link to="/" className="blog2-text11">
                  SERVICES
                </Link>
                <Link to="/blog" className="blog2-text12">
                  BLOG
                </Link>
                <Link to="/contact" className="blog2-text13">
                  CONTACT
                </Link>
              </div>
            </div>
            <div>
              <div className="blog2-container3">
                <a
                  href="https://www.instagram.com/cianatagram/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog2-link"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 16 3 C 8.8545455 3 3 8.8545455 3 16 L 3 34 C 3 41.145455 8.8545455 47 16 47 L 34 47 C 41.145455 47 47 41.145455 47 34 L 47 16 C 47 8.8545455 41.145455 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.054545 5 45 9.9454545 45 16 L 45 34 C 45 40.054545 40.054545 45 34 45 L 16 45 C 9.9454545 45 5 40.054545 5 34 L 5 16 C 5 9.9454545 9.9454545 5 16 5 z M 37 11 C 35.9 11 35 11.9 35 13 C 35 14.1 35.9 15 37 15 C 38.1 15 39 14.1 39 13 C 39 11.9 38.1 11 37 11 z M 25 14 C 18.954545 14 14 18.954545 14 25 C 14 31.045455 18.954545 36 25 36 C 31.045455 36 36 31.045455 36 25 C 36 18.954545 31.045455 14 25 14 z M 25 16 C 29.954545 16 34 20.045455 34 25 C 34 29.954545 29.954545 34 25 34 C 20.045455 34 16 29.954545 16 25 C 16 20.045455 20.045455 16 25 16 z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/people/Cianata/61555814410893/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog2-link1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 25 3 C 12.861562 3 3 12.861562 3 25 C 3 36.019135 11.127533 45.138355 21.712891 46.728516 L 22.861328 46.902344 L 22.861328 29.566406 L 17.664062 29.566406 L 17.664062 26.046875 L 22.861328 26.046875 L 22.861328 21.373047 C 22.861328 18.494965 23.551973 16.599417 24.695312 15.410156 C 25.838652 14.220896 27.528004 13.621094 29.878906 13.621094 C 31.758714 13.621094 32.490022 13.734993 33.185547 13.820312 L 33.185547 16.701172 L 30.738281 16.701172 C 29.349697 16.701172 28.210449 17.475903 27.619141 18.507812 C 27.027832 19.539724 26.84375 20.771816 26.84375 22.027344 L 26.84375 26.044922 L 32.966797 26.044922 L 32.421875 29.564453 L 26.84375 29.564453 L 26.84375 46.929688 L 27.978516 46.775391 C 38.71434 45.319366 47 36.126845 47 25 C 47 12.861562 37.138438 3 25 3 z M 25 5 C 36.057562 5 45 13.942438 45 25 C 45 34.729791 38.035799 42.731796 28.84375 44.533203 L 28.84375 31.564453 L 34.136719 31.564453 L 35.298828 24.044922 L 28.84375 24.044922 L 28.84375 22.027344 C 28.84375 20.989871 29.033574 20.060293 29.353516 19.501953 C 29.673457 18.943614 29.981865 18.701172 30.738281 18.701172 L 35.185547 18.701172 L 35.185547 12.009766 L 34.318359 11.892578 C 33.718567 11.811418 32.349197 11.621094 29.878906 11.621094 C 27.175808 11.621094 24.855567 12.357448 23.253906 14.023438 C 21.652246 15.689426 20.861328 18.170128 20.861328 21.373047 L 20.861328 24.046875 L 15.664062 24.046875 L 15.664062 31.566406 L 20.861328 31.566406 L 20.861328 44.470703 C 11.816995 42.554813 5 34.624447 5 25 C 5 13.942438 13.942438 5 25 5 z"></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com/CianataX"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog2-link2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Nav;

import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";
import Nav from "./views/Nav";
import Blog from "./views/blog";
import Contact from "./views/contact";
import Home from "./views/home";
import NotFound from "./views/not-found";

import Blog3 from "./views/blogs/blog3";
import Blog5 from "./views/blogs/blog5";
import Blog1 from "./views/blogs/blog1";
import Blog4 from "./views/blogs/blog4";
import Blog2 from "./views/blogs/blog2";

import NewNav from "./views/newNav";

const App = () => {
  return (
    <Router>
      {/* <NewNav /> */}
      <Nav />
      <Switch>
        <Route component={Contact} exact path="/contact" />
        <Route component={Home} exact path="/" />
        <Route component={Blog3} exact path="/blog3" />
        <Route component={Blog5} exact path="/blog5" />
        <Route component={Blog} exact path="/blog" />
        <Route component={Blog1} exact path="/blog1" />
        <Route component={Blog4} exact path="/blog4" />
        <Route component={Blog2} exact path="/blog2" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
      <ToastContainer />
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
